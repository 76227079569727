/*
File: scss
*/
// @import '~bootstrap/scss/bootstrap';

$cocemfeBlue: #003c69!default;
$cocemfeOrange: #df7a00!default;
$cocemfeGreen: #00a599!default;
$footerBg: #003c69!default;
$footerColor: #ffffff!default;
//$footerBg: #bed4e3!default;
$defaultColor: #212529!default;
$cocemfeBg: #f6f6f6!default;
$faqsBg: #f6f6f6!default;

img {
    max-width: 100%;
}

.contact-form {
    .form-error-icon {
        display: none;
    }
    .input-group {
        margin-bottom: 10px;
    }
    .checkbox .form-check {
      display: flex;
    }
    input[type="checkbox"] {
      width: 25px !important;
      height: 25px !important;
      margin-right: 15px !important;
      position: relative;
      padding: 0 !important;
    }
    .form-radio >label {
      margin-bottom: 15px;
    }
    .form-radio .form-group {
      display: flex;
      width: 100%;
    }
    input[type="radio"] {
      width: 20px !important;
      height: 20px !important;
      margin-bottom: 0px;
      margin-left: 8px;
      margin-right: 4px;
    }
}

.btn {
	padding: 12px 25px;	
}

.navbar-brand {
    img {
        max-width: 100%;
        max-height: 100%;
        //width: 262px;
        //transition: width 300ms ease-in-out;
    }
    a {
        display: inline-block;
        transition: all 300ms ease-in-out;
        &.logo-accesibilidapp {
            width: 180px;
        }
        &.logo-cocemfe {
            height: 40px;
        }
        &.logo-fundacion {
            height: 40px;
            margin-left: 20px;
        }
    }
}

header.sticky {
    .navbar-brand {
        a {
            transition: all 300ms ease-in-out;
            &.logo-accesibilidapp {
                width: 160px;
            }
            &.logo-cocemfe {
                height: 30px;
            }
            &.logo-fundacion {
                height: 30px;
                margin-left: 20px;
            }
        }
    }
}

.navbar-nav {
    .nav-item {
        position: relative;
        .nav-link {
            display: flex;
        }
        .dropdown-menu {
            margin-top: -20px;
            font-size: 15px;
            padding-top: 0;
            padding-bottom: 0;
            .dropdown-item {
                padding-right: 40px;
            }
        }
    }
}

#dropdownMenuLanguages {
    &:after {
        display: none;
    }
}

.features-icon,
.features-icon-img {
    text-align: center;
    img {
        max-width: 100px;
    }
}
.btn {
    &.btn-perpil {
        color: $cocemfeBlue!important;
        background: transparent!important;
        border: 2px solid $cocemfeBlue,
    }
    &:hover {
			transform: none;
			background:$cocemfeBlue !important;
			color: white !important;
    }
}
.app-item {
    position: relative;
    box-shadow: none!important;
    a {
        padding: 25px;
        display: inline-block;
        margin-top:-10px;
        img {
            margin-top: 0;
        }
    }
    img {
        transition: width 600ms ease-in-out;
        width: 100%!important;
        margin-left: auto;
        margin-right: auto;
        margin-top: 25px;
        max-width: 80%;
    }
    .smartphone-border {
        opacity: 0;
        transition: opacity 600ms ease-in-out;
        position: absolute; 
        z-index: 999;
        width: 100%!important;
        margin-top: 0!important;
        left: 30px;
    }
    &.slick-current {
        box-shadow: none!important;
        * {
            box-shadow: none!important;
        }
        .smartphone-border {
            opacity: 1;
        }
    }
}

.faq-wrapper {
	.card-header {
		h5 {
			font-size: 14px;
			button {
				font-size: 14px;
				letter-spacing: 0.5pt;
				@media only screen and (max-width: 640px) {
					display: block;
					white-space: inherit;
					font-size: 12px;
					line-height: 16px;
				}		

			}
		}
	}
}
.faq-wrapper-2 {
    .btn-link:not(.collapsed) {
        background: $cocemfeGreen;
    }
}

footer {
    background-color: $footerBg;
    color: $footerColor;
    img {
        max-width: 260px;
    }
    .footer-icon {
    	a {
	    	& i {
	        color: $footerColor;
	        border: 1px solid $footerColor;
	        background: transparent;
	    	}
	    	&:hover {
		    	i {
			    	background: lighter($footerColor, 10%);
			    	color: $footerBg;
		    	}
	    	}
    	}
    }
    .footer-text h4 {
        color: $footerColor;
        font-weight: 800;
    }
}
.copyright-2 {
    border-top-color: $footerColor;
    a {
        color: $footerColor;
        &:hover {
            color: darken($footerColor, 20%);
        }
    }
}
.image-white {
    filter: contrast(0) brightness(200%);
}
.copyright p {
        color: $footerColor!important;
}

#scrollUp {
    display: flex;
    align-items: center;
    justify-content: center;
}

#faq {
    background-color: $faqsBg;
}
#cocemfe {
    background-color: $cocemfeBg;
}
.blog-thumb {
    height: 260px;
    background-size: auto 100%;
    background-position: center;
    background-repeat: no-repeat;
    a {
        width: 100%;
        height: 100%;
        display: block;
    }
}
.page-title-area {
    background-size: auto 100%;
    background-repeat: no-repeat;
    background-position: right;
    padding-bottom: 40px;
    &::before {
        background: transparent;
    }
}
.page-title {
    h1 {
        color: #000;
    }
}
.breadcrumb {
    background-color: transparent;
    li {
        list-style: none;
    }
}

.main-tabs {
    margin-top: 210px;
    a {
        padding: 10px 58px;
        color: black;
        border: 2px solid $cocemfeOrange;
        font-weight: 500;
        &:nth-child(1) {
            border-radius: 30px 0 0 30px;
        }
        &:nth-child(2) {
            border-radius: 0 30px 30px 0;
        }
        &.active {
            background-color: $cocemfeOrange;
            color: white;
        }
        &:hover:not(.active) {
            color: $cocemfeOrange;
        }
    }
}
.main-tabs-content {
    .tab-content-wrapper {
        display: none;
        &.active {
            display: block;
        }
    }
}
.app-active-manage .app-item.slick-center {
    text-align: center;
}

.silder-content {
	h1 {
		color:$cocemfeBlue;
		font-weight: 800;
		@media only screen and (max-width: 640px) {
			font-size: 40px;
		}
	}
}
.get-app { 
	a {
		&:hover {
			background:$cocemfeOrange;
			border-color: $cocemfeOrange;
			& i, p {
				color: white;
			}
		}
	}
    .logo-zp {
        max-width: 150px;
        display: inline-block;
        float: right;
    }
}
.area-title {
	h2 {
		font-weight: 800;
	}
	.wrapper {
		color: white;
		padding-top: 20px;
	}
}
.about-app {
	h2 {
		font-weight: 800;
	}
    &.features-area h2 {
        margin-bottom: 60px;
    }
}
.about-cocemfe-area {
	.about-app-thumb {
        text-align: center;
		img {
		    //width: 60%;
            //max-width: 260px;
            margin-right: auto;
            margin-left: auto;
		}	
	}
}

#features, #contact {
	.area-title {
		.wrapper {
			color: #666;
		}
	}
}

.contact-info-2 {
	.contact-icon i {
		border: 1px solid $cocemfeBlue;
		color: $cocemfeBlue;
	}
}

.slider-app-thumb {
	@media only screen and (max-width: 640px) {
		right:0;
		top:15px;
	}	
}
.screenshot-area {
	@media only screen and (max-width: 640px) {
		padding: 0 0 60px;
	}		
}
.app-padding {
	@media only screen and (max-width: 640px) {
		padding: 0 30px;
	}		
}
@media (max-width: 991px) {
    .main-tabs {
        a {
            padding: 10px 20px;
            display: block;
            width: 80%;
            margin-left: auto;
            margin-right: auto;
            &:nth-child(1) {
                border-radius: 30px 30px 0 0;
            }
            &:nth-child(2) {
                border-radius: 0 0 30px 30px;
            }
        }
    }
    .navbar-icon {
        background: $cocemfeGreen;
    }
    .slider-height {
        min-height: 0;
        margin-top: 80px;
        margin-bottom: 80px;
    }
    .header-transparent {
        z-index: 9999;
    }
    header.header-area {
        .navbar-collapse {
            position: absolute;
            width: 100%;
            top: 46px;
            width: calc(100% + 30px);
            left: -15px;
            &.show {
                box-shadow: 0px 5px 6px #dadada;
            }
            ul.navbar-nav {
                margin-top: 18px;
            }
        }
    }
    .main-menu ul.navbar-nav {
        background-image: none;
        background-color: white;
    }
}

/* Cookies and Legal pages */

/*
.route-frontend-cookies, .route-frontend-legal  {
	.page-title-area {
		padding-top: 200px;
	}
}

.route-frontend-cookies {
  ol {
  	margin:0;
  	padding:0;
  }
  
  table td,table th {
  	padding:0;
  }
  
  .c15 {
  	border-right-style:solid;
  	padding:2pt 3pt;
  	border-bottom-color:#000;
  	border-top-width:1pt;
  	border-right-width:1pt;
  	border-left-color:#000;
  	vertical-align:top;
  	border-right-color:#000;
  	border-left-width:1pt;
  	border-top-style:solid;
  	background-color:#fff;
  	border-left-style:solid;
  	border-bottom-width:1pt;
  	width:44.1pt;
  	border-top-color:#000;
  	border-bottom-style:solid;
  }
  
  .c30 {
  	border-right-style:solid;
  	padding:2pt 3pt;
  	border-bottom-color:#000;
  	border-top-width:1pt;
  	border-right-width:1pt;
  	border-left-color:#000;
  	vertical-align:top;
  	border-right-color:#000;
  	border-left-width:1pt;
  	border-top-style:solid;
  	background-color:#d9d9d9;
  	border-left-style:solid;
  	border-bottom-width:1pt;
  	width:44.1pt;
  	border-top-color:#000;
  	border-bottom-style:solid;
  }
  
  .c33 {
  	border-right-style:solid;
  	padding:2pt 3pt;
  	border-bottom-color:#000;
  	border-top-width:1pt;
  	border-right-width:1pt;
  	border-left-color:#000;
  	vertical-align:top;
  	border-right-color:#000;
  	border-left-width:1pt;
  	border-top-style:solid;
  	background-color:#d9d9d9;
  	border-left-style:solid;
  	border-bottom-width:1pt;
  	width:68.6pt;
  	border-top-color:#000;
  	border-bottom-style:solid;
  }
  
  .c34 {
  	border-right-style:solid;
  	padding:2pt 3pt;
  	border-bottom-color:#000;
  	border-top-width:1pt;
  	border-right-width:1pt;
  	border-left-color:#000;
  	vertical-align:top;
  	border-right-color:#000;
  	border-left-width:1pt;
  	border-top-style:solid;
  	background-color:#d9d9d9;
  	border-left-style:solid;
  	border-bottom-width:1pt;
  	width:312.8pt;
  	border-top-color:#000;
  	border-bottom-style:solid;
  }
  
  .c4 {
  	border-right-style:solid;
  	padding:2pt 3pt;
  	border-bottom-color:#000;
  	border-top-width:1pt;
  	border-right-width:1pt;
  	border-left-color:#000;
  	vertical-align:top;
  	border-right-color:#000;
  	border-left-width:1pt;
  	border-top-style:solid;
  	background-color:#fff;
  	border-left-style:solid;
  	border-bottom-width:1pt;
  	width:312.8pt;
  	border-top-color:#000;
  	border-bottom-style:solid;
  }
  
  .c14 {
  	border-right-style:solid;
  	padding:2pt 3pt;
  	border-bottom-color:#000;
  	border-top-width:1pt;
  	border-right-width:1pt;
  	border-left-color:#000;
  	vertical-align:top;
  	border-right-color:#000;
  	border-left-width:1pt;
  	border-top-style:solid;
  	background-color:#fff;
  	border-left-style:solid;
  	border-bottom-width:1pt;
  	width:68.6pt;
  	border-top-color:#000;
  	border-bottom-style:solid;
  }
  
  .c1 {
  	-webkit-text-decoration-skip:none;
  	color:#444;
  	font-weight:400;
  	text-decoration:underline;
  	vertical-align:baseline;
  	text-decoration-skip-ink:none;
  	font-size:12pt;
  	font-style:normal;
  }
  
  .c2 {
  	color:#000;
  	font-weight:400;
  	text-decoration:none;
  	vertical-align:baseline;
  	font-size:13pt;
  	font-style:normal;
  }
  
  .c0 {
  	color:#444;
  	font-weight:400;
  	text-decoration:none;
  	vertical-align:baseline;
  	font-size:12pt;
  	font-style:normal;
  }
  
  .c21 {
  	color:#000;
  	font-weight:400;
  	text-decoration:none;
  	vertical-align:baseline;
  	font-size:9.5pt;
  	font-style:normal;
  }
  
  .c3 {
  	color:#000;
  	font-weight:700;
  	text-decoration:none;
  	vertical-align:baseline;
  	font-size:9pt;
  	font-style:normal;
  }
  
  .c7 {
  	
  	
  	line-height:1;
  	orphans:2;
  	widows:2;
  	text-align:justify;
  }
  
  .c23 {
  	padding-top:0;
  	padding-bottom:0;
  	line-height:1;
  	orphans:2;
  	widows:2;
  	text-align:left;
  }
  
  .c12 {
  	padding-top:0;
  	padding-bottom:15pt;
  	line-height:1;
  	orphans:2;
  	widows:2;
  	text-align:justify;
  }
  
  .c31 {
  	padding-top:0;
  	
  	line-height:1;
  	orphans:2;
  	widows:2;
  	text-align:left;
  }
  
  .c35 {
  	padding-top:0;
  	padding-bottom:0;
  	line-height:1;
  	orphans:2;
  	widows:2;
  	text-align:justify;
  }
  
  .c29 {
  	
  	
  	line-height:1;
  	orphans:2;
  	widows:2;
  	text-align:left;
  }
  
  .c26 {
  	
  	padding-bottom:0;
  	line-height:1;
  	orphans:2;
  	widows:2;
  	text-align:left;
  }
  
  .c25 {
  	padding-top:0;
  	padding-bottom:10pt;
  	line-height:1.15;
  	orphans:2;
  	widows:2;
  	text-align:left;
  }
  
  .c13 {
  	border-spacing:0;
  	border-collapse:collapse;
  	margin-right:auto;
  }
  
  .c24 {
  	color:#000;
  	text-decoration:none;
  	vertical-align:baseline;
  	font-style:normal;
  }
  
  .c19 {
  	font-size:18pt;
  	color:#0aa7a4;
  	font-weight:400;
  }
  
  .c17 {
  	font-size:17pt;
  	color:#0aa7a4;
  	font-weight:400;
  }
  
  .c27 {
  	font-weight:400;
  	font-size:11pt;
  	font-size:16pt;
  	font-weight:400;
  }
  
  .c10 {
  	font-size:13pt;
  	font-weight:400;
  }
  
  .c5 {
  	font-size:12pt;
  	font-weight:400;
  }
  
  .c20 {
  	background-color:#fff;
  	max-width:425.2pt;
  	padding:70.8pt 85pt;
  }
  
  .c8 {
  	padding:0;
  	margin:0;
  }
  
  .c11 {
  	margin-left:36pt;
  	padding-left:0;
  }
  
  .c18 {
  	font-style:italic;
  }
  
  .c22 {
  	height:35pt;
  }
  
  .c16 {
  	height:11pt;
  }
  
  .c6 {
  	color:#444;
  }
  
  .c32 {
  	color:#0aa7a4;
  }
  
  .title {
  	padding-top:0;
  	color:#000;
  	font-size:11pt;
  	padding-bottom:10pt;
  	line-height:1.15;
  	orphans:2;
  	widows:2;
  	text-align:left;
  }
  
  .subtitle {
  	padding-top:0;
  	color:#595959;
  	font-size:12pt;
  	padding-bottom:0;
  	line-height:1;
  	orphans:2;
  	widows:2;
  	text-align:left;
  }
  
  li {
  	color:#000;
  	font-size:11pt;
  	color:#000;
  	font-size:11pt;
  	color:#597397;
  	font-size:28pt;
  	padding-bottom:10pt;
  	line-height:1.15;
  	page-break-after:avoid;
  	orphans:2;
  	widows:2;
  	text-align:left;
  }
  
  h2 {
  	padding-top:2pt;
  	color:#003c69;
  	font-size:24pt;
  	padding-bottom:10pt;
  	line-height:1.15;
  	page-break-after:avoid;
  	orphans:2;
  	widows:2;
  	text-align:left;
  }
  
  h3 {
  	padding-top:2pt;
  	color:#e97a00;
  	font-size:22pt;
  	padding-bottom:10pt;
  	line-height:1.15;
  	page-break-after:avoid;
  	orphans:2;
  	widows:2;
  	text-align:left;
  }
  
  h4 {
  	padding-top:2pt;
  	color:#00a599;
  	font-size:20pt;
  	padding-bottom:10pt;
  	line-height:1.15;
  	page-break-after:avoid;
  	orphans:2;
  	widows:2;
  	text-align:left;
  }
  
  h5 {
  	padding-top:11pt;
  	color:#000;
  	font-weight:700;
  	font-size:11pt;
  	padding-bottom:2pt;
  	line-height:1.15;
  	page-break-after:avoid;
  	orphans:2;
  	widows:2;
  	text-align:left;
  }
  
  h6 {
  	padding-top:10pt;
  	color:#000;
  	font-weight:700;
  	font-size:10pt;
  	padding-bottom:2pt;
  	line-height:1.15;
  	page-break-after:avoid;
  	orphans:2;
  	widows:2;
  	text-align:left;
  }
}

.route-frontend-legal {
  ol {
  	margin:0;
  	padding:0;
  }
  
  table td,table th {
  	padding:0;
  }
  
  .c14 {
  	border-right-style:solid;
  	padding:3.8pt;
  	border-bottom-color:#000;
  	border-top-width:0;
  	border-right-width:0;
  	border-left-color:#000;
  	vertical-align:top;
  	border-right-color:#000;
  	border-left-width:0;
  	border-top-style:solid;
  	border-left-style:solid;
  	border-bottom-width:0;
  	width:234.2pt;
  	border-top-color:#000;
  	border-bottom-style:solid;
  }
  
  .c19 {
  	border-right-style:solid;
  	padding:3.8pt;
  	border-bottom-color:#000;
  	border-top-width:0;
  	border-right-width:0;
  	border-left-color:#000;
  	vertical-align:top;
  	border-right-color:#000;
  	border-left-width:0;
  	border-top-style:solid;
  	border-left-style:solid;
  	border-bottom-width:0;
  	width:190.9pt;
  	border-top-color:#000;
  	border-bottom-style:solid;
  }
  
  .c4 {
  	margin-left:36pt;
  	padding-top:0;
  	padding-left:0;
  	padding-bottom:0;
  	line-height:1;
  	orphans:2;
  	widows:2;
  	text-align:left;
  }
  
  .c20 {
  	margin-left:36pt;
  	
  	padding-left:0;
  	padding-bottom:0;
  	line-height:1;
  	orphans:2;
  	widows:2;
  	text-align:left;
  }
  
  .c31 {
  	margin-left:36pt;
  	padding-top:0;
  	padding-left:0;
  	
  	line-height:1;
  	orphans:2;
  	widows:2;
  	text-align:justify;
  }
  
  .c22 {
  	margin-left:36pt;
  	
  	padding-left:0;
  	padding-bottom:0;
  	line-height:1;
  	orphans:2;
  	widows:2;
  	text-align:justify;
  }
  
  .c16 {
  	margin-left:36pt;
  	padding-top:0;
  	padding-left:0;
  	padding-bottom:0;
  	line-height:1;
  	orphans:2;
  	widows:2;
  	text-align:justify;
  }
  
  .c33 {
  	margin-left:36pt;
  	padding-top:0;
  	padding-left:0;
  	line-height:1;
  	orphans:2;
  	widows:2;
  	text-align:left;
  }
  
  .c34 {
  	color:#fff;
  	font-weight:400;
  	text-decoration:none;
  	vertical-align:baseline;
  	font-size:9pt;
  	font-style:normal;
  }
  
  .c3 {
  	color:#000;
  	font-weight:400;
  	text-decoration:none;
  	vertical-align:baseline;
  	font-size:10pt;
  	font-style:normal;
  }
  
  .c5 {
  	color:#444;
  	font-weight:400;
  	text-decoration:none;
  	vertical-align:baseline;
  	font-size:9pt;
  	font-style:normal;
  }
  
  .c42 {
  	padding-top:0;
  	padding-bottom:18pt;
  	line-height:1.15;
  	orphans:2;
  	widows:2;
  	text-align:left;
  	height:11pt;
  }
  
  .c35 {
  	color:#353535;
  	font-weight:400;
  	text-decoration:none;
  	vertical-align:baseline;
  	font-size:11pt;
  	font-style:normal;
  }
  
  .c25 {
  	color:#0aa7a4;
  	font-weight:700;
  	text-decoration:none;
  	vertical-align:baseline;
  	font-size:22pt;
  	font-style:normal;
  }
  
  .c2 {
  	color:#0aa7a4;
  	font-weight:400;
  	text-decoration:none;
  	vertical-align:baseline;
  	font-size:17pt;
  	font-style:normal;
  }
  
  .c0 {
  	color:#444;
  	font-weight:400;
  	text-decoration:none;
  	vertical-align:baseline;
  	font-size:12pt;
  	font-style:normal;
  }
  
  .c51 {
  	padding-top:0;
  	
  	line-height:1;
  	orphans:2;
  	widows:2;
  	text-align:justify;
  }
  
  .c52 {
  	padding-top:7.5pt;
  	
  	line-height:1.15;
  	orphans:2;
  	widows:2;
  	text-align:justify;
  }
  
  .c6 {
  	padding-top:0;
  	padding-bottom:18pt;
  	line-height:1.15;
  	orphans:2;
  	widows:2;
  	text-align:justify;
  }
  
  .c7 {
  	
  	
  	line-height:1;
  	orphans:2;
  	widows:2;
  	text-align:left;
  }
  
  .c15 {
  	
  	
  	line-height:1;
  	orphans:2;
  	widows:2;
  	text-align:justify;
  }
  
  .c56 {
  	padding-top:0;
  	padding-bottom:18pt;
  	line-height:1.15;
  	orphans:2;
  	widows:2;
  	text-align:left;
  }
  
  .c44 {
  	padding-top:0;
  	padding-bottom:7.5pt;
  	line-height:1;
  	orphans:2;
  	widows:2;
  	text-align:left;
  }
  
  .c8 {
  	padding-top:0;
  	
  	line-height:1.15;
  	orphans:2;
  	widows:2;
  	text-align:justify;
  }
  
  .c10 {
  	
  	
  	line-height:1;
  	orphans:2;
  	widows:2;
  	text-align:left;
  }
  
  .c9 {
  	padding-top:0;
  	
  	line-height:1;
  	orphans:2;
  	widows:2;
  	text-align:left;
  }
  
  .c46 {
  	
  	
  	line-height:1.15;
  	orphans:2;
  	widows:2;
  	text-align:left;
  }
  
  .c12 {
  	
  	
  	line-height:1;
  	orphans:2;
  	widows:2;
  	text-align:justify;
  }
  
  .c48 {
  	color:#0aa7a4;
  	font-weight:700;
  	font-size:12pt;
  }
  
  .c38 {
  	margin-left:-3.8pt;
  	border-spacing:0;
  	border-collapse:collapse;
  	margin-right:auto;
  }
  
  .c17 {
  	color:#1f292a;
  	text-decoration:none;
  	vertical-align:baseline;
  	font-style:normal;
  }
  
  .c26 {
  	-webkit-text-decoration-skip:none;
  	color:#00f;
  	text-decoration:underline;
  	text-decoration-skip-ink:none;
  }
  
  .c30 {
  	font-size:12pt;
  	color:#1f292a;
  	font-weight:700;
  }
  
  .c43 {
  	font-size:12.5pt;
  	color:#fff;
  	font-weight:400;
  }
  
  .c1 {
  	font-size:13.5pt;
  	color:#479904;
  	font-weight:400;
  }
  
  .c29 {
  	color:#000;
  	text-decoration:none;
  	vertical-align:baseline;
  	font-style:normal;
  }
  
  .c41 {
  	font-size:17pt;
  	color:#0aa7a4;
  	font-weight:400;
  }
  
  .c49 {
  	font-size:18pt;
  	color:#0aa7a4;
  	font-weight:400;
  }
  
  .c27 {
  	font-size:14pt;
  	color:#0aa7a4;
  	font-weight:400;
  }
  
  .c54 {
  	font-size:12pt;
  	font-weight:700;
  }
  
  .c21 {
  	font-size:13pt;
  	font-weight:400;
  }
  
  .c57 {
  	-webkit-text-decoration-skip:none;
  	text-decoration:underline;
  	text-decoration-skip-ink:none;
  }
  
  .c40 {
  	background-color:#fff;
  	max-width:425.2pt;
  	padding:70.8pt 85pt;
  }
  
  .c37 {
  	font-weight:400;
  	font-size:13.5pt;
  }
  
  .c39 {
  	text-decoration:none;
  	vertical-align:baseline;
  	font-style:normal;
  }
  
  .c13 {
  	font-size:12pt;
  	font-weight:400;
  }
  
  .c55 {
  	font-weight:400;
  }
  
  .c45 {
  	color:inherit;
  	text-decoration:inherit;
  }
  
  .c18 {
  	padding:0;
  	margin:0;
  }
  
  .c36 {
  	margin-left:36pt;
  	padding-left:0;
  }
  
  .c47 {
  	color:#353535;
  }
  
  .c53 {
  	color:#000;
  }
  
  .c32 {
  	color:#1f292a;
  }
  
  .c23 {
  	background-color:#999;
  }
  
  .c24 {
  	color:#444;
  }
  
  .c28 {
  	color:#ee8201;
  }
  
  .c58 {
  	color:red;
  }
  
  .c50 {
  	font-style:italic;
  }
  
  .c11 {
  	height:0;
  }
  
  .title {
  	padding-top:24pt;
  	color:#000;
  	font-weight:700;
  	font-size:36pt;
  	padding-bottom:6pt;
  	line-height:1.15;
  	page-break-after:avoid;
  	orphans:2;
  	widows:2;
  	text-align:left;
  }
  
  .subtitle {
  	padding-top:18pt;
  	color:#666;
  	font-size:24pt;
  	padding-bottom:4pt;
  	line-height:1.15;
  	page-break-after:avoid;
  	font-style:italic;
  	orphans:2;
  	widows:2;
  	text-align:left;
  }
  
  li {
  	color:#000;
  	font-size:11pt;
  }
  
  p {
  	margin:0;
  	color:#000;
  	font-size:11pt;
  }
  
  h1 {
  	padding-top:0;
  	color:#000;
  	font-size:12pt;
  	padding-bottom:10pt;
  	line-height:1;
  	orphans:2;
  	widows:2;
  	text-align:left;
  }
  
  h2 {
  	padding-top:0;
  	color:#000;
  	font-size:12pt;
  	padding-bottom:10pt;
  	line-height:1;
  	orphans:2;
  	widows:2;
  	text-align:left;
  }
  
  h3 {
  	
  	color:#000;
  	font-weight:700;
  	font-size:14pt;
  	padding-bottom:4pt;
  	line-height:1.15;
  	page-break-after:avoid;
  	orphans:2;
  	widows:2;
  	text-align:left;
  }
  h4 {
  	padding-top:2pt;
  	color:#366091;
  	font-size:11pt;
  	padding-bottom:0;
  	line-height:1.15;
  	page-break-after:avoid;
  	font-style:italic;
  	orphans:2;
  	widows:2;
  	text-align:left;
  }
  .footer-text {
		h4 {
			font-style: inherit;
			font-size: 24px;	
		}  
  }
  h5 {
  	padding-top:11pt;
  	color:#000;
  	font-weight:700;
  	font-size:11pt;
  	padding-bottom:2pt;
  	line-height:1.15;
  	page-break-after:avoid;
  	orphans:2;
  	widows:2;
  	text-align:left;
  }
  
  h6 {
  	padding-top:2pt;
  	color:#243f61;
  	font-size:11pt;
  	padding-bottom:0;
  	line-height:1.15;
  	page-break-after:avoid;
  	orphans:2;
  	widows:2;
  	text-align:left;
  }  
}
*/

.dropdown-item.disabled, .dropdown-item:disabled {
    opacity: 0.6;
}

ol {
    li {
        list-style: initial;
    }
}

@media only screen and (max-width: 1199px) {
    .main-menu ul.navbar-nav li {
        margin-left: 15px;
    }
    .navbar-brand {
        a {
            &.logo-accesibilidapp {
                width: 160px;
            }
            &.logo-cocemfe {
                height: 30px;
            }
            &.logo-fundacion {
                height: 30px;
                margin-left: 15px;
            }
        }
    }

    header.sticky {
        .navbar-brand {
            a {
                transition: all 300ms ease-in-out;
                &.logo-accesibilidapp {
                    width: 150px;
                }
                &.logo-cocemfe {
                    height: 25px;
                }
                &.logo-fundacion {
                    height:25px;
                    margin-left: 15px;
                }
            }
        }
    }
}
@media only screen and (max-width: 767px) {
    .navbar-brand {
        img {
            max-width: 100%;
            max-height: 100%;
        }
        a {
            &.logo-accesibilidapp {
                width: 96px;
            }
            &.logo-cocemfe {
                height: 18px;
            }
            &.logo-fundacion {
                height: 18px;
                margin-left: 10px;
            }
        }
    }

    header.sticky {
        .navbar-brand {
            a {
                &.logo-accesibilidapp {
                    width: 96px;
                }
                &.logo-cocemfe {
                    height: 18px;
                }
                &.logo-fundacion {
                    height: 18px;
                    margin-left: 10px;
                }
            }
        }
    }
}

.slider-2 .get-app a.disabled {
    background: #9e9e9e!important;
    border-color: #9e9e9e!important;
    cursor: default!important;
}
/* Honeypot contact field */
.contact-url {
    height: 0!important;
    width: 0!important;
    border: none!important;
}

.frontend-video-wrapper {
    margin-top: 150px;
    video {
        max-width: 980px;
        margin-bottom: -120px;
    }
}
