/*
  Theme Name: Maxapp - App Landing HTML5 Template
  Author: nilArtStudio
  Description: App landing  HTML5 template.
  Version: 1.0
*/

/* CSS Index
-----------------------------------
1. Theme default css
2. header
3. slider
4. about-app-area
5. how-it-area-area
6. features
7. fact
8. screenshot
9. testimonial
10. pricing
11. Faq
12. download
13. blog
14. map
15. contact-form
16. footer
17. page title
*/


/* 1. Theme default css */
@import url('https://fonts.googleapis.com/css?family=Libre+Franklin:300,400,500,600,700,800');
 body {
	font-family: 'Libre Franklin', sans-serif;
	font-weight: 400;
	font-style: normal;
	font-size: 16px
}
.img {
	max-width: 100%;
	transition: all 0.3s ease-out 0s;
}
.f-left {
	float: left
}
.f-right {
	float: right
}
.fix {
	overflow: hidden
}
a,
.button {
	-webkit-transition: all 0.3s ease-out 0s;
	-moz-transition: all 0.3s ease-out 0s;
	-ms-transition: all 0.3s ease-out 0s;
	-o-transition: all 0.3s ease-out 0s;
	transition: all 0.3s ease-out 0s;
}
a:focus,
.button:focus {
	text-decoration: none;
	outline: none;
}
a:focus,
a:hover,
.portfolio-cat a:hover,
.footer -menu li a:hover {
	color: #2B96CC;
	text-decoration: none;
}
a,
button {
	color: #1696e7;
	outline: medium none;
}
button{cursor: pointer;}
button:focus,input:focus,input:focus,textarea,textarea:focus{outline: 0}
.uppercase {
	text-transform: uppercase;
}
.capitalize {
	text-transform: capitalize;
}
h1,
h2,
h3,
h4,
h5,
h6 {
	font-family: 'Libre Franklin', sans-serif;
	color: #444444;
	margin-top: 0px;
	font-style: normal;
	font-weight: 600;
	text-transform: normal;
	margin-bottom: 15px;
}
h1 a,
h2 a,
h3 a,
h4 a,
h5 a,
h6 a {
	color: inherit;
}
h1 {
	font-size: 48px;
}
h2 {
	font-size: 40px;
}
h3 {
	font-size: 32px;
}
h4 {
	font-size: 24px;
}
h5 {
	font-size: 18px;
}
h6 {
	font-size: 16px;
}
ul {
	margin: 0px;
	padding: 0px;
}
li {
	list-style: none
}
p {
	font-size: 16px;
	font-weight: normal;
	line-height: 30px;
	color: #666666;
	margin-bottom: 15px;
}
hr {
	border-bottom: 1px solid #eceff8;
	border-top: 0 none;
	margin: 30px 0;
	padding: 0;
}
label {
	color: #7e7e7e;
	cursor: pointer;
	font-size: 14px;
	font-weight: 400;
}
*::-moz-selection {
	background: #d6b161;
	color: #fff;
	text-shadow: none;
}
::-moz-selection {
	background: #444;
	color: #fff;
	text-shadow: none;
}
::selection {
	background: #444;
	color: #fff;
	text-shadow: none;
}
*::-moz-placeholder {
	color: #555555;
	font-size: 14px;
	opacity: 1;
}
*::placeholder {
	color: #555555;
	font-size: 14px;
	opacity: 1;
}
.theme-overlay {
	position: relative
}
.theme-overlay::before {
	background: #1696e7 none repeat scroll 0 0;
	content: "";
	height: 100%;
	left: 0;
	opacity: 0.6;
	position: absolute;
	top: 0;
	width: 100%;
}
.separator {
	border-top: 1px solid #f2f2f2
}
/* button style */
.btn {
	-moz-user-select: none;
	background: transparent;
	border: medium none;
	border-radius: 2px;
	color: #fff;
	cursor: pointer;
	display: inline-block;
	font-size: 14px;
	font-weight: 600;
	letter-spacing: 1px;
	line-height: 1;
	margin-bottom: 0;
	padding: 17px 35px;
	text-align: center;
	touch-action: manipulation;
	transition: all 0.3s ease 0s;
	vertical-align: middle;
	white-space: nowrap;
	border-radius: 30px;
	transition: .3s;
}
.btn:hover{transform: translateY(-5px);color: #fff;}
.btn.btn-blue{
  background: #3aa6fe;
  color: #fff;
}
.btn.btn-perpil{
  background: #003c69;
  color: #fff;
}

.owl-carousel .owl-nav div {
	background: rgba(255, 255, 255, 0.8) none repeat scroll 0 0;
	height: 40px;
	left: 20px;
	line-height: 40px;
	opacity: 0;
	position: absolute;
	text-align: center;
	top: 50%;
	transform: translateY(-50%);
	transition: all 0.3s ease 0s;
	visibility: hidden;
	width: 40px;
}
.owl-carousel .owl-nav div.owl-next {
	left: auto;
	right: 20px;
}
.owl-carousel:hover .owl-nav div {
	opacity: 1;
	visibility: visible;
}
.owl-carousel .owl-nav div:hover {
	background: #2B96CC;
	color: #fff
}

/* scrollUp */
#scrollUp {
	background: #000;
	height: 40px;
	width: 40px;
	right: 50px;
	bottom: 77px;
	color: #fff;
	font-size: 17px;
	text-align: center;
	border-radius: 50%;
	font-size: 16px;
	line-height: 40px;
}
#scrollUp:hover {
	background: #444;
}
.area-title{}
.area-title h2 {
	font-weight: 700;
	line-height: 1;
}
.area-title.white-text h2{color: #fff}
.area-title.white-text p{color: #fff}
.area-title p{margin: 0}


/* 2. header */
.header-transparent {
	position: absolute;
	top: 0;
	left: 0;
	right: 0;
	z-index: 9;
	transition: .4s;
}
.navbar.navbar-expand-lg {
	padding: 0;
}
.main-menu ul.navbar-nav{}
.main-menu ul.navbar-nav li {
	margin-left: 35px;
}
.main-menu ul.navbar-nav li a.nav-link {
	font-size: 15px;
	font-weight: 500;
	color: #fff;
	padding: 30px 0;
	line-height: 1;
	position: relative;
}
.main-menu ul.navbar-nav li a.nav-link::before {
	position: absolute;
	content: "";
	height: 2px;
	width: 0;
	left: -15px;
	top: 37px;
	background: #fff;
	transition: 0.3s;
}
.main-menu ul.navbar-nav li.active a.nav-link:before,.main-menu ul.navbar-nav li a.nav-link:hover:before{width: 12px;}
.sticky {
	left: 0;
	margin: auto;
	position: fixed;
	top: 0;
	width: 100%;
	box-shadow: 0px 0px 85px 0px rgba(0, 0, 0, 0.14);
	z-index: 1030;
	padding: 3px 0;
	background: #3AA6FE;
}
.pink-header.sticky {
  background: #ffff
}
.header-white .main-menu ul.navbar-nav li a.nav-link {
	color: #7d4196;
}
.header-white .main-menu ul.navbar-nav li a.nav-link::before {
	background: #ff039c;
}

.header-2 .main-menu ul.navbar-nav li a.nav-link {
	color: #222222;
}
.header-2 .main-menu ul.navbar-nav li.active a.nav-link,.header-2 .main-menu ul.navbar-nav li a.nav-link:hover {
	color: #003c69;
}
.header-2 .main-menu ul.navbar-nav li a.nav-link::before {
	display: none;
}
.header-2.sticky {
	background: #fff;
}
.header-2 {}
.header-height{min-height: 95px;}

/* 3. slider */
.pos-relative {
	position: relative;
	z-index: 1;
}
.slider-height {
	min-height: 650px;
}
.silder-content {
	position: relative;
	z-index: 999;
}
.silder-content h1 {
	font-size: 60px;
	font-weight: 700;
	padding-right: 40px;
}
.silder-content p {
	color: #fff;
	font-size: 17px;
	line-height: 32px;
	margin-bottom: 30px;
}
.silder-content a {
	margin-right: 15px;
}
.slider-app-thumb {
	position: relative;
	z-index: 99;
	right: -50px;
}
.slider-app-thumb img {
	position: relative;
	z-index: 99;
    max-width: 250px;
}
.slider-3 .slider-height {
	min-height: 850px;
}


/* slider-pink */
.slider-2 .silder-content > p {
	color: #444;
}
.slider-2 .get-app a {
	border: 1px solid #003c69;
	color: #fff;
	background: #003c69;
}
#controlBar_home,.mb_YTPBar {
    display: none !important;
}

.blue-opacity::before {
	background-image: -moz-linear-gradient( 0deg, rgba(0,122,223,0.3) 0%, rgba(0,236,188,0.1) 100%);
	background-image: -webkit-linear-gradient( 0deg, rgba(0,122,223,0.3) 0%, rgba(0,236,188,0.1) 100%);
	background-image: -ms-linear-gradient( 0deg, rgba(0,122,223,0.3) 0%, rgba(0,236,188,0.1) 100%);
	content: "";
	height: 100%;
	left: 0;
	position: absolute;
	top: 0;
	width: 100%;
	z-index: 1;
	opacity: .1
}
.yellow-opacity::before {
background-image: -moz-linear-gradient( 0deg, rgba(250,112,154,0.90196) 0%, rgba(254,225,64,0.90196) 100%);
	background-image: -webkit-linear-gradient( 0deg, rgba(250,112,154,0.90196) 0%, rgba(254,225,64,0.90196) 100%);
	background-image: -ms-linear-gradient( 0deg, rgba(250,112,154,0.90196) 0%, rgba(254,225,64,0.90196) 100%);
	content: "";
	height: 100%;
	left: 0;
	position: absolute;
	top: 0;
	width: 100%;
	z-index: 1;
	opacity: .8
}


/* features */
.features-wrapper{}
.features-wrapper h3{}
.features-wrapper p {
	margin: 0;
	padding: 0 35px;
}
.features-icon{}
.features-icon img{}
.features-arrow{position: relative;}
.features-arrow:before{/*background-image: url(../../images/frontend/icon/arrow.png);*/content: "";height: 130px;width:710px;position: absolute;bottom: 0}

/* yello features */
.features-4 p {
	padding: 0 15px;
}

/* 4. about-app-area */
.about-app-area{position: relative;}
.about-app-thumb.about-simple img {
	margin-top: -40px;
	width: 80%;
}
.about-app{}
.about-app h2 {
	color: #444;
	margin-bottom: 23px;
}
.about-app p {

}
.color-white{}
.get-app a {
	border: 1px solid #fff;
	padding: 10px 30px;
	display: inline-block;
	color: #fff;
	line-height: 1;
	border-radius: 49px;
	margin-right: 18px;
}
.get-app-2 a {
	border: 1px solid #003c69;
	color: #fff;
	background: #003c69;
}
.get-app a p b {
	font-weight: 600;
	font-size: 14px;
}
.get-app a:hover{background: #fff;}
.get-app a i {
	float: left;
	font-size: 25px;
	position: relative;
	top: 8px;
	transition:.3s;
}
.get-app.black-btn a{}
.get-app.black-btn a p{}
.get-app.black-btn a p b{}
.get-app a p {
	display: inline-block;
	margin: 0;
	line-height: 1.4;
	float: left;
	padding-left: 12px;
	color: #fff;
	text-align: left;
	transition: .3s;
	font-size: 13px;
}
.get-app.download-app {
	position: relative;
	z-index: 99;
}
.download-app a{margin: 0 9px}
.about-app-thumb {
	text-align: right;

}
.about-simple {
	margin-bottom: 0px;
}
.about-simple img {
	margin-top: -100px;
}
.get-app a:hover{
  background: #fff;
  border-color: #fff;
}
.get-app a:hover p{color:#666;}
.get-app a:hover i{color:#666;}
.about-app-thumb img {

}
.about-gray h2 {
	color: #333333;
}
.about-gray p {
	color: #7c7c7c;
}
.get-app a.pink-gradient {
	border: 1px solid transparent;
}
.get-app a.pink-gradient:hover {
	box-shadow: 0px 10px 20px 0px rgba(56, 56, 56, 0.25);
}
/* 5. how-it-area-area */
.video {
	position: relative;
}
.video span {
	color: #fff;
	font-weight: 500;
	margin-left: 10px;
	font-size: 18px;
}
.video {
}
.video a {
	width: 90px;
	margin: auto;
	height: 90px;
	display: inline-block;
}

.how-it-work-content-area{
	padding-top: 275px;
}
.work-box {
	background: #fff;
	padding: 45px 35px;
}
.work-box.work-box-no-bg {
	background: #fff;
	padding: 0px 35px;
}
.work-box-2 {
	padding: 0px 35px;
}
.work-box h3{}
.work-box p{margin: 0}
.video-area{}
.video-height {
	min-height: 600px;
	background-position: center center;
	background-size: cover;
	box-shadow: inherit;
}
.video.video-right {
	margin-bottom: 0;
	box-shadow: none;
}

/* 6. features */
.main-features {
	border: 1px solid #ddd;
	padding: 50px 40px 42px;
}
.features-content {
	overflow: hidden;
	padding-left: 0;
	margin-top: 20px;
}
.features-icon {

}
.features-icon span {
	display: inline-block;
	height: 60px;
	margin-left: 0;
	position: relative;
	width: 60px;
}
.features-content p {
	margin: 0;
}
.features-content h4 {
	font-size: 20px;
	margin-bottom: 10px;
}

/* 7. fact */
.border-shape{border-bottom: 1px solid #eeeeee}
.single-fact {
	position: relative;
	z-index: 99;
}
.single-fact i {
  display: block;
  color: #fff;
  font-size: 52px;
  margin-bottom: 20px;
}
.single-fact span {
	color: #fff;
	font-size: 32px;
	font-weight: 700;
	line-height: 1;
}
.single-fact h5 {
	font-size: 16px;
	color: #fff;
	margin: 0;
	margin-top: 18px;
	font-weight: 500;
}
/* fact white */
.single-fact.fact-2 i {
	color: #003c69;
}
.single-fact.fact-2 h5 {
	color: #444444;
}
.single-fact.fact-2 span {
	color: #444444;
}
/* 8. screenshot */
.screenshot-area{}
.app-padding{padding: 0 120px;}
.app-item {
	margin: 0 15px;
	box-shadow: 0 0 7px  rgba(0,0,0,.2);
}
.app-item img {
	width: 100%;
	transition: .3s;
}
.app-active-manage .slick-track{padding: 80px 0}
.app-active .slick-track{padding: 80px 0}
.app-item.slick-center{}
.app-item.slick-center img {
	transform: scale(1.10);
}
.app-item.slick-center a img {
	box-shadow: 0 10px 30px  rgba(19,19,19,.2);
}
.app-active-manage .slick-dots,
.app-active .slick-dots {
	text-align: center;
}
.app-active-manage .slick-dots li,
.app-active .slick-dots li {
	display: inline-block;
	margin: 0 4px
}
.app-active-manage .slick-dots li button,
.app-active .slick-dots li button {
	height: 15px;
	width: 15px;
	text-indent: -9999px;
	transition: .3s;
	padding: 0;
	border-radius: 50%;
	border: 1px solid #3aa6fe;
	background: transparent;
}
.app-active-manage .slick-dots li.slick-active button{background: #3aa6fe}
.app-active .slick-dots li.slick-active button{background: #3aa6fe}

.app-active-2 .slick-dots li button {
	border: 1px solid #003c69;
}
.app-active-2 .slick-dots li.slick-active button{background: #003c69}

/* 9. testimonial */
.testimonial-item {
	padding: 0;
	background: #fff;
}
.inner-tesimonial {
	box-shadow: 0px 10px 30px 0px rgba(87, 87, 87, 0.1);
	padding: 35px 35px;
	position: relative;
	text-align: ;
}
.testimonial-item P {
	margin-bottom: 35px;
}
.testimonail-name h6 {
	color: #8a8a8a;
	font-family: 'Open Sans', sans-serif;
	margin-bottom: 8px;
	line-height: 1;
}
.testimonail-name span {
	font-size: 13px;
	color: #acacac;
}
.inner-tesimonial::after {
	position: absolute;
	bottom: 38px;
	background-image: url(../../images/frontend/icon/qoute.png);
	right: 40px;
	width: 45px;
	height: 39px;
	z-index: 9;
	content: "";
	margin: auto;
}
.yellow-quote::after {
	background-image: url(../../images/frontend/icon/y-quote.png);
}
.red-quote::after {
	background-image: url(../../images/frontend/icon/y-quote.png);
}
.pink-quote::after {
	background-image: url(../../images/frontend/icon/y-quote.png);
}
/* testimonial-nav */
.testimonial-nav {
	margin: 0 auto auto;
	text-align: center;
	width: 310px;
}
.testimonial-nav button.slick-next {
  left: auto;
  right: -50px;
}
.testimonial-thumb > img {
  transform: scale(0.7);
}
.testimonial-thumb.slick-center > img {
  transform: scale(01);
}
.testimonial-active button.slick-arrow {
	position: absolute;
	top: 50%;
	left: -100px;
	transform: translateY(-50%);
	background: none;
	border: 0;
	background: #fff;
	width: 80px;
	font-size: 35px;
	padding: 0;
	color: #444;
	z-index: 9;
	border-radius: 30px;
	transition: .3s;
}
.testimonial-active button.slick-next{left: auto;right:-100px}
.testimonial-active button.slick-arrow:hover{color: #00ecbc}
.yellow-active button.slick-arrow:hover{color: #fa709a}
.red-active button.slick-arrow:hover{color: #dc2430}
.pink-active button.slick-arrow:hover{color: #ff3594}


/* 10. pricing */
.price-head{position: relative;}
.price-head h2 {
	color: #444444;
	font-size: 36px;
	padding: 40px 0;
	line-height: 1;
	margin: 0;
	padding-bottom: 50px;
	padding-bottom: 35px;
}
.price-list {
	padding: 45px 0;
}
.price-list ul li {
	font-size: 16px;
	color: #8a8a8a;
	margin-bottom: 30px;
	line-height: 1;
}
.price-list ul li:last-child{margin: 0}
.price-count h2 {
	margin-bottom: 5px;
	font-weight: 700;
}
span.month {
	font-size: 16px;
	color: #8a8a8a;
}
.price-count h2 span {
	font-size: 22px;
}
.price-count h2 span.usd {
	font-size: 16px;
}
.price-pink-head {
	/*background-image: url(../../images/frontend/bg/price-head-shape.png);*/
	background-position: center center;
	background-repeat: no-repeat;
	padding: 50px 0;
}
.price-pink-head:after{display: none;}
.price-pink-head h2 {
	padding: 0;
	font-size: 24px;
}
.price-pink {
	padding-top: 40px;
}

.price-head.pink-gradient:after{display: none}
.price-padding {
	padding-left: 30px;
	padding-right: 30px;
}


/* 11. Faq */
.faq-wrapper .btn-link {
	border: 0 none;
	color: #fff;
	font-size: 18px;
	font-weight: 500;
	padding: 0;
	width: 100%;
	padding: 20px 25px;
	border-radius: 0;
	text-align: left;
	border: 0;
	border: 1px solid #fff;
	text-decoration: none;
	transform: translateY(0);
	background: #3aa6fe;
}
.faq-wrapper .btn-link.collapsed{
	background: transparent;
	border: 1px solid #ddd;
	color: #444
}
.faq-wrapper .btn-link.collapsed:hover{
	border: 1px solid #ddd;
}
.faq-wrapper .btn-link::before {
	content: "\e622";
	font-family: 'themify';
	line-height: 30px;
	position: absolute;
	right: 20px;
	top: 15px;
	font-size: 24px;
	font-size: 14px;
}
.faq-wrapper .btn-link.collapsed::before {
	content: "\e61a";
	line-height: 30px;
	position: absolute;
	font-size: 14px;
	right: 20px;
}
.btn-link:focus{text-decoration: none}
.faq-wrapper .card-header {
  background: rgba(0, 0, 0, 0) none repeat scroll 0 0;
  border: 0 none;
  margin-bottom: 0;
  padding: 0;
  position: relative;
}
.faq-wrapper .card {
	-moz-box-direction: normal;
	-moz-box-orient: vertical;
	background-clip: border-box;
	background-color: #fff;
	border: 0 none;
	border-radius: 0;
	display: flex;
	flex-direction: column;
	margin-bottom: 20px;
	min-width: 0;
	overflow-wrap: break-word;
	position: relative;
}
.faq-wrapper .card-body {
	-moz-box-flex: 1;
	flex: 1 1 auto;
	padding: 0;
	color: #8a8a8a;
	font-size: 14px;
	line-height: 26px;
	padding: 15px 10px;
}
.faq-img {
	margin-top: -38px;
	text-align: right;
	padding-right: 100px;
}
.faq-wrapper-2{}
.faq-wrapper-2 .btn-link {
	background: #4E54C8;
}
.faq-img-2 {
	margin-top: -18px;
	padding-right: 0px;
}
/* 12. download */
.star-pattern{position: relative;}
.star-pattern::before {
	/*background-image: url("../../images/frontend/shape/star.png");*/
	background-repeat: no-repeat;
	top: 0;
	content: "";
	height: 533px;
	position: absolute;
	left: -59px;
	transition: all 0.3s ease 0s;
	width: 445px;
	z-index: 9;
}
.shape-squire::before {
	/*background-image: url("../../images/frontend/shape/shape-squire.png");*/
	background-repeat: no-repeat;
	top: 20px;
	content: "";
	height: 378px;
	position: absolute;
	left: 0;
	transition: all 0.3s ease 0s;
	width: 266px;
	z-index: 9;
}
.smart-watch {
	position: absolute;
	right: 50px;
	bottom: -80px;
}

/* 13. blog */
.blue-blog{}
.blog-thumb img {
	width: 100%;
}
.blog-content h5{margin-bottom: 10px;line-height: 30px}
.blog-list .blog-content h5 {
	font-size: 24px;
	line-height: 1.5;
}
.blue-blog h5 a:hover{color: #007adf}
.purpil-blog h5 a:hover{color: #4e53c8}
.yellow-blog h5 a:hover{color: #fe5196}
.red-blog h5 a:hover{color: #dc2430}
.pink-blog h5 a:hover{color: #ff3494}
.blog-meta span {
	font-size: 12px;
	color: #777;
	margin-right: 10px;
}
.blog-meta span a {
	color: #666;
}
.blog-meta span a:hover {
	color: #222;
}
.blog-content p {
	margin: 0;
}
/* sidebar */
.widget {
	overflow: hidden;
}
.widget-title {
	color: #444444;
	display: inline-block;
	font-size: 16px;
	font-weight: bold;
	height: auto;
	letter-spacing: 1px;
	line-height: 1;
	margin-bottom: 20px;
	padding: 0 0 10px;
	position: relative;
}
.widget-title::before {
  background: #e2e2e2 none repeat scroll 0 0;
  bottom: 0;
  content: "";
  height: 2px;
  position: absolute;
  width: 50px;
}
.sidebar-form{}
.sidebar-form form {
  position: relative;
}
.sidebar-form form input {
  background: rgba(0, 0, 0, 0) none repeat scroll 0 0;
  border: 1px solid #eee;
  color: #ccc;
  padding: 10px 0;
  text-indent: 10px;
  transition: all 0.2s ease 0s;
  width: 100%;
}
.sidebar-form form input::-moz-placeholder {
  color: #999;
  font-size: 12px;
  opacity: .5;
}
.sidebar-form form button {
  background: #222 none repeat scroll 0 0;
  border: 0 none;
  color: #fff;
  font-size: 20px;
  height: 100%;
  position: absolute;
  right: 0;
  top: 0;
  transition: all 0.3s ease 0s;
  width: 50px;
}
.sidebar-form form button:hover{background:#007adf;}
.sidebar-rc-post{}
.sidebar-rc-post ul{}
.sidebar-rc-post ul li {
  border-bottom: 1px solid #f5f5f5;
  color: #000;
  display: block;
  font-size: 13px;
  margin-bottom: 15px;
  overflow: hidden;
  padding-bottom: 15px;
}
.sidebar-rc-post ul li:last-child{border:0;margin:0;padding:0;;}
.sidebar-rc-post .rc-post-thumb {
  display: inline-block;
  float: left;
  height: 70px;
  overflow: hidden;
  position: relative;
  width: 90px;
}
.sidebar-rc-post .rc-post-content {
  margin-left: 105px;
}
.sidebar-rc-post .rc-post-content h4 {
	color: #444;
	display: block;
	font-size: 13px;
	font-weight: 700;
	line-height: 19px;
	margin: 0;
	text-indent: 0;
	transition: all 0.2s ease 0s;
}
.sidebar-rc-post .rc-post-content h4 a:hover{color:#007adf}
.widget-date {
	color: #777;
	display: inline-block;
	float: left;
	font-size: 10px;
	line-height: 1;
	margin: 10px 5px 0 0;
	padding: 0 8px 0 0;
	text-decoration: none;
	text-indent: 0;
	text-transform: uppercase;
}
.sidebar-blog .widget{margin-bottom:35px;}
.sidebar-blog .widget:last-child{margin-bottom:0;}
.widget-social{}
.widget-social a {
  background: #000 none repeat scroll 0 0;
  color: #fff;
  display: inline-block;
  font-size: 19px;
  height: 45px;
  line-height: 45px;
  margin-right: 12px;
  text-align: center;
  width: 45px;
}
.widget-social a.facebook{background:#3b5998 }
.widget-social a.twitter{background:#1da1f2 }
.widget-social a.instagram{background:#dd4b39 }
.widget-social a.googleplus{background:#dd4b39 }
.widget-social a.linkedin{background:#0077b5 }
.widget-social a:hover{opacity: 0.8;}
.sidebar-link{}
.sidebar-link li {
  border-bottom: 1px solid #f5f5f5;
  color: #000;
  display: block;
  font-size: 13px;
  line-height: 20px;
  margin-bottom: 10px;
  padding-bottom: 10px;
}
.sidebar-link li:last-child{border:0;padding:0;margin:0;}
.sidebar-link li a {
	color: #444;
	font-weight: 600;
	padding-left: 5px;
}
.sidebar-link li a:hover{color:#007adf}
.sidebar-link li span{float:right;}
.instagram-link{margin:0 -5px}
.instagram-link li {
  float: left;
  margin-bottom: 10px;
  padding: 0 5px;
  width: 33.33%;
}
.instagram-link li a{}
.instagram-link li img {
	width: 100%;
}
.widget-banner img {
	width: 100%;
}
.sidebar-tad{}
.sidebar-tad li {
  float: left;
  margin-bottom: 5px;
  margin-right: 5px;
}
.sidebar-tad li a {
	background: #f7f7f7 none repeat scroll 0 0;
	color: #444;
	display: inline-block;
	font-size: 12px;
	font-weight: 600;
	line-height: 1;
	padding: 12px 20px;
	text-transform: uppercase;
}
.sidebar-tad li a:hover{background:#007adf;color:#fff}


/* blog details */
.blog-details p{margin-bottom: 20px;}
.blockquote {
  border-left: 5px solid #e6e6e6;
  font-size: 1.25rem;
  margin-bottom: 35px;
  margin-left: 50px;
  margin-top: 35px;
  padding-left: 15px;
}
.blog-content-img img {
	width: 100%;
}
.blog-post-tag > a {
	border: 1px solid #ededed;
	color: #444;
	display: inline-block;
	font-size: 12px;
	padding: 10px 15px;
	margin-bottom: 5px;
}
.blog-post-tag > a:hover{background: #007ADF;border-color: #007ADF;color: #fff}
.blog-share-icon > span {
  color: #595959;
  font-size: 15px;
}
.blog-share-icon> a {
  color: #595959;
  font-size: 15px;
  margin: 0 8px;
}
.blog-share-icon> a:hover{color: #007ADF}
.blog-share-icon {
  margin-top: 8px;
}
/* blog comments */
.section-title h2 {
	font-size: 24px;
}
.comments-avatar {
  float: left;
  width: 103px;
}
.comments-text {
  overflow: hidden;
  padding-left: 30px;
}
.avatar-name > h5 {
    float: left;
    font-size: 14px;
    font-weight: 600;
}
.avatar-name > span {
    color: #646464;
    float: right;
    font-size: 12px;
}
.avatar-name {
    margin-bottom: 10px;
    overflow: hidden;
}
.comments-box {
  margin-bottom: 50px;
}
.comments-reply {
  padding-left: 130px;
}
.comments-text > p {
    color: #646464;
    font-size: 14px;
    line-height: 24px;
    margin-bottom: 25px;
}
.comments-text > a {
    border: 1px solid #ddd;
    color: #444;
    display: inline-block;
    font-size: 13px;
    font-weight: 500;
    padding: 7px 20px;
    text-transform: uppercase;
}
.comments-text > a:hover{background: #007ADF;border-color: #007ADF;color: #fff}

.post-comments-form {}
.post-comments-form input {
  border: 1px solid #e6e6e6;
  height: 45px;
  margin-bottom: 25px;
  padding: 0 15px;
  width: 100%;
}
.post-comments-form textarea {
  border: 1px solid #e6e6e6;
  height: 130px;
  margin-bottom: 25px;
  padding: 15px;
  width: 100%;
}
.post-comments-form  button{}


/* blog pagination */
.blog-pagination ul li {
	display: inline-block;
	margin: 0 2px;
	margin-bottom: 8px;
}
.blog-pagination ul li a {
	height: 40px;
	width: 40px;
	background: #f3f3f3;
	display: inline-block;
	color: #8a8a8a;
	line-height: 40px;
	font-size: 16px;
	text-align: center;
}
.blog-pagination ul li a:hover, .blog-pagination ul li.active a {
	background: #3AA6FE;
	color: #fff;
}

/* 14. map */
#contact-map{height: 650px;width: 100%}
.subscribe-box {
	margin-bottom: -92px;
	position: relative;
	z-index: 9;
}
.subscribe-inner h1 {
	color: #fff;
}
.subscribe-inner {
}
.subscribe-inner h1 {
	color: #fff;
	display: inline-block;
	margin: 0;
	line-height: 1;
	position: relative;
	top: 10px;
	margin-right: 30px;
}
.subscribe-inner {
	text-align: center;
}
.subscribe-form{display: inline-block;}
.subscribe-form form{position: relative;}
.subscribe-form form input {
	width: 560px;
	border: 0;
	border-radius: 30px;
	height: 60px;
	padding: 0 30px;
}
.subscribe-form form button.btn {
	position: absolute;
	right: 0;
	border: 0;
	top: 9px;
	right: 13px;
	padding: 15px 35px;
	box-shadow: 0;
	box-shadow: none;
	margin: 0;
	transform: translateY(0px);
}
.subscribe-form form button.btn:hover {
	box-shadow: 0px 10px 20px 0px rgba(56, 56, 56, 0.25);
}

/* 15. contact-form */
.contact-form{}
.contact-form form input {
	width: 100%;
	border: 1px solid #ddd;
	margin-bottom: 20px;
	height: 50px;
	padding: 0 15px;
	border-radius: 0;
}
.contact-form form textarea {
	border: 1px solid #ddd;
	width: 100%;
	border-radius: 0px;
	padding: 25px;
	height: 130px;
	margin-bottom: 20px;
}
.contact-form form button{}
.contact-icon {
	float: left;
}
.contact-text {
	overflow: hidden;
	padding-left: 12px;
}
.contact-info ul li {
	margin-bottom: 30px;
}
.contact-icon i {
	border: 1px solid #3AA6FE;
	color: #3AA6FE;
	height: 40px;
	width: 40px;
	display: inline-block;
	border-radius: 50%;
	text-align: center;
	line-height: 40px;
	font-size: 16px;
}
.contact-text h5 {
	font-weight: 500;
	margin-bottom: 8px;
	line-height: 1;
}
.contact-text span {
	font-size: 14px;color: #8a8a8a;
}
.contact-info-2 .contact-icon i {
	border: 1px solid #4E54C8;
	color: #4E54C8;
}
.contact-info-2{}
.contact-info-2{}
.contact-info-2{}


/* 16. footer */
.footer-icon a i {
	border: 1px solid #fff;
	color: #fff;
	height: 35px;
	width: 35px;
	display: inline-block;
	border-radius: 50%;
	text-align: center;
	line-height: 34px;
	font-size: 14px;
	transition: .3s;
}
.yellow-icon a i{
	border: 1px solid #fa7199;
	color: #fa7199;
}
.footer-icon-white a i{
	border: 1px solid #fff;
	color: #fff;
}
.footer-icon a {
	margin-right: 4px;
}
.footer-icon a:hover i{background: #fff;color: #222}
.yellow-icon a:hover i{background: #fa7199;color: #fff}
.yellow-icon a:hover i{background: #fa7199;color: #fff}
.footer-icon-white a:hover i{
	color: #444;
	background:#fff;
}
.footer-text p {
	color: #fff;
	margin: 0;
}
.footer-text.white-text p {
	color: #fff;
}
.footer-text h4 {
	color: #fff;
	margin-bottom: 30px;
}
.copyright {
	border-top: 1px solid #83c7fe;
}
.copyright-2 {
	border-top: 1px solid #444;
}
.copyright p {
	color: #fff;
	margin: 0;
	text-align: center;
}
.bg-footer {
	background-size: cover;
	background-position: bottom left;
}


.subscribe-inner.footer-subscribe h1 {
	display: block;
	position: static;
	margin-right: 0px;
	margin-bottom: 40px;
}
.footer-subscribe{}

/* 17. page title */
.page-title-area {
	padding-top: 320px;
	padding-bottom: 220px;
	background-size: cover;
	background-position: center center;
	position: relative;
}
.page-title-area::before {
	content: "";
	position: absolute;
	top: 0;
	left: 0;
	height: 100%;
	width: 100%;
	background: #000;
	opacity: .7;
}
.page-title h1 {
	color: #fff;
	font-size: 48px;
	margin: 0;
}
.breadcrumb-item > a {
  color: #fff;
  font-size: 17px;
}
.breadcrumb {
	background: rgba(0, 0, 0, 0) none repeat scroll 0 0;
	border-radius: 0;
	margin: 0;
	padding: 0;
	margin-top: 15px;
}
.breadcrumb-item + .breadcrumb-item::before {
	color: #fff;
	content: "/";
}
.breadcrumb > .active {
	color: #fff;
}

/*------------------------------------------------------------------
[17.Preloader]
*/

.page-loader {
	background: #fff;
	position: fixed;
	top: 0;
	bottom: 0;
	right: 0;
	left: 0;
	z-index: 9998;
}

.loader {
  background-image: -moz-linear-gradient( 0deg, rgb(0,122,223) 0%, rgb(0,236,188) 100%);
  background-image: -webkit-linear-gradient( 0deg, rgb(0,122,223) 0%, rgb(0,236,188) 100%);
  background-image: -ms-linear-gradient( 0deg, rgb(0,122,223) 0%, rgb(0,236,188) 100%);
	position: absolute;
	display: inline-block;
	height: 60px;
	width: 60px;
	left: 50%;
	top: 50%;
	margin: -30px 0 0 -30px;
	text-indent: -9999em;
	-webkit-border-radius: 100%;
	   -moz-border-radius: 100%;
	        border-radius: 100%;
	-webkit-animation-fill-mode: both;
	        animation-fill-mode: both;
	-webkit-animation: ball-scale 1s 0s ease-in-out infinite;
	        animation: ball-scale 1s 0s ease-in-out infinite;
}

@-webkit-keyframes ball-scale {

	0% {
		-webkit-transform: scale(0);
		   -moz-transform: scale(0);
		    -ms-transform: scale(0);
		     -o-transform: scale(0);
		        transform: scale(0);
	}

	100% {
		opacity: 0;
		-webkit-transform: scale(1);
		   -moz-transform: scale(1);
		    -ms-transform: scale(1);
		     -o-transform: scale(1);
		        transform: scale(1);
	}

}

@keyframes ball-scale {

	0% {
		-webkit-transform: scale(0);
		   -moz-transform: scale(0);
		    -ms-transform: scale(0);
		     -o-transform: scale(0);
		        transform: scale(0);
	}

	100% {
		opacity: 0;
		-webkit-transform: scale(1);
		   -moz-transform: scale(1);
		    -ms-transform: scale(1);
		     -o-transform: scale(1);
		        transform: scale(1);
	}

}
